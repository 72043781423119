/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prettier/prettier */
import { useFormik } from "formik";
import * as yup from "yup";
import { ModalContent, Card, Common } from "@/components";
import { manifest } from "../models";
import { covertStartDateStringToDate, covertTime12ToDate } from "@/pages/SchedulePlanner/utils";
import { useRef } from "react";
import ReactDatePicker from "react-datepicker";
import moment from "moment";

const validationSchema = yup.object().shape({
  fixedStartTime: yup
    .string()
    .matches(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):(0[0-9]|[0-9]|[0-5][0-9])$/, "Start time is not valid. (valid format hh:mm)"),
});

interface Props {
  open: boolean;
  data: manifest;
  onClose: () => void;
  onSubmit: (fixedStartTime: string | null) => void;
  onReset: (fixedStartTime: string | null) => void;
}


export const ModalChangeStartTime = ({
  open,
  data,
  onSubmit,
  onClose,
  onReset,
}: Props) => {
  const timePickerRef = useRef<ReactDatePicker>();
  const formik = useFormik({
    initialValues: {
      fixedStartTime: data?.fixedStartTime ?? "",
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      onSubmit(values.fixedStartTime ?? "");
      // formik.resetForm();
    },
    onReset: (values) => {
      // onReset(values.fixedStartTime ?? "");
    },
  });

  const handleClose = () => {
    onClose();
    // formik.resetForm();
  };

  return (
    <ModalContent
      open={open}
      onClose={handleClose}
      panelClassWidth="max-w-[31.25rem] w-full"
    >
      <Card
        title={`Change start time`}
      >
        <div className="p-4">

          <Common.Input
            type="time"
            label={`Enter start time (format hh:mm)`}
            name="fixedStartTime"
            value={formik.values.fixedStartTime}
            onChange={formik.handleChange}
            isError={formik.touched.fixedStartTime && Boolean(formik.errors.fixedStartTime)}
            errorMessage={formik.touched.fixedStartTime ? formik.errors.fixedStartTime : ""}
            onKeyDown={(e) => e.key === "Enter" && formik.handleSubmit()}
          />
          
        </div>
        <div className="shadow-overline px-4 py-3 flex space-x-2 justify-end">
          <Common.Button onClick={handleClose} color="transparent">
            Cancel
          </Common.Button>
          <Common.Button
            disabled={
              (formik.touched.fixedStartTime && Boolean(formik.errors.fixedStartTime))
            }
            onClick={() => formik.handleSubmit()}
          >
            Submit
          </Common.Button>
        </div>
      </Card>

    </ModalContent>
  );
};
